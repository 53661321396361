import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../scss/Hamburger.scss";

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMenu = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="hamburger-menu" ref={dropdownRef}>
            <button onClick={toggleMenu}>
                <i class="fa fa-bars"></i>
            </button>
            {isOpen && (
                <div className="dropdown">
                    <Link to="/" onClick={closeMenu}>Home</Link>
                    <Link to="/gallery" onClick={closeMenu}>Gallery</Link>
                    <Link to="/about" onClick={closeMenu}>About</Link>
                </div>
            )}
        </div>
    );
};

class Navigation extends React.Component {
    render() {
        return (
            <>
                <nav className="main-navigation">
                    <div>&nbsp;</div>
                    <img src="/images/logo.png" alt="Water Colors by Peter" />
                    <div>
                        <HamburgerMenu />
                        <div className="pages">
                            <Link to="/">Home</Link>
                            <Link to="/gallery">Gallery</Link>
                            <Link to="/about">About</Link>
                        </div>
                    </div>
                </nav>
                <div className="divider thick"></div>
            </>
        );
    }
}

export default Navigation;
