import React from "react";

class About extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="header-panel">
                    <h1>PETER JOHN SALADINO</h1>
                </div>
                <div className="sub-info-left-border add-margin">
                    Artist, Engineer, Dad, Grandpa
                </div>
                <div className="about-content">
                    <div className="collage">
                        <div className="row">
                            <div className="column">
                                <img
                                    src="/images/peter2.jpg"
                                    alt="Peter Saladino"
                                />
                            </div>
                            <div className="column">
                                <img
                                    src="/images/peter.png"
                                    alt="Peter Saladino"
                                />
                            </div>
                            <div className="column">
                                <img
                                    src="/images/peter3.jpg"
                                    alt="Peter Saladino"
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="content">
                        <p>
                            Peter J Saladino was a fine man, Dad, Grandpa, Great Grandpa,
                            and most importantly husband to Vincenza Saladino, the love of his life.
                        </p>
                        <p>
                            He was born in Brooklyn, New York on October 19, 1935. He became interested
                            in art at a very young age. He met our mom Vincenza while enlisted in
                            the US Navy stationed in Naples, Italy. They fell in love and married
                            in Naples in 1957. They would go on to create a family of 6 having 4
                            daughters, their family continued to grow with 8 grandchildren, and
                            7 great-grandchildren. Peter and Vincenza continued their love story
                            for over 62 years.
                        </p>
                        <p>
                            Peter J Saladino had many admirable traits. Amongst them he was kind,
                            compassionate, proud of his family, loving, appreciative, insightful,
                            wise, uplifting to all, patient, and modest. He was a talented aerospace
                            engineer, draftsman, and artist who enjoyed a good sense of humor, and
                            sharing stories about his family.  He always had a generous heart and
                            kind words for all.
                        </p>
                        <p>
                            His legacy lives on through the cherished memories with family and friends,
                            and the beauty created through his artistic talents.
                        </p>
                        <p>
                            Peter was an artist who appreciated the beauty in life, nature, our world,
                            and the simplest things. He got the utmost enjoyment through creating and
                            sharing these beautiful pieces. His favorite genre was watercolors, yet he
                            also created beautiful acrylic paintings. Each piece was created with love.
                            It is impossible to look at his work and not be awed by his talent, and the
                            beauty of each painting.
                        </p>
                        <p>
                            We are honored to be able to uphold his wishes and be able to share by
                            compiling as many pieces as possible into this online library.  We do know
                            in our hearts he would be so pleased.
                        </p>
                        <p>
                            Please join us in celebrating Peter's life and legacy. Thank you for taking
                            the time to appreciate and enjoy.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
