import React from "react";
import { Link } from "react-router-dom";

class Home extends React.Component {
    render() {
        return (
            <>
                <div className="home-image">
                    &nbsp;
                </div>
                <div className="home-bio-panel">
                    <h2>PETER JOHN SALADINO</h2>
                    <div className="divider"></div>
                    <br />
                    <div>
                        <div>1935 - 2019</div>
                        <p>
                            <strong>Welcome to Watercolors by Peter,</strong>{" "}
                            a website dedicated to the artwork and legacy of Peter J Saladino.
                            A fine man, Dad, Grandpa, Great Grandpa, and most importantly
                            husband to Vincenza Saladino, the love of his life.
                            Peter was an artist who appreciated the beauty in life,
                            nature, our world, and the simplest things.
                        </p>
                    </div>
                    <div>
                        <Link to="/about">
                            <button>
                                Read more
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="panel">
                    <div className="collage">
                        <h3>ARTWORK SAMPLES</h3>
                        <div className="row">
                            <div className="column">
                                <img src="/images/art/watercolor/watermarked/pjs004.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs006.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs008.jpg" alt="" loading="lazy" />
                            </div>
                            <div className="column">
                                <img src="/images/art/watercolor/watermarked/pjs018.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs005.jpg" alt="" loading="lazy" />
                            </div>
                            <div className="column">
                                <img src="/images/art/watercolor/watermarked/pjs080.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs090.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs110.jpg" alt="" loading="lazy" />
                            </div>
                            <div className="column">
                                <img src="/images/art/watercolor/watermarked/pjs153.jpg" alt="" loading="lazy" />
                                <img src="/images/art/watercolor/watermarked/pjs155.jpg" alt="" loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="see-more-btn">
                        <Link to="/gallery">
                            <button>
                                See gallery
                            </button>
                        </Link>
                    </div>
                    <br />
                </div>
            </>
        );
    }
}

export default Home;
