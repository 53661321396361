import React, { lazy, Suspense } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import "./scss/App.scss";
import Navigation from "./components/Navigation";
import Home from "./views/Home";
import About from "./views/About";
import Footer from "./components/Footer";
import ClipLoader from "react-spinners/ClipLoader";

const Gallery = lazy(() => import('./views/Gallery'));

class App extends React.Component {
    componentDidMount() {
        // prevent right click save
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        // prevent drag and drop
        document.addEventListener('dragstart', event => {
            event.preventDefault();
        });
        document.addEventListener('drop', event => {
            event.preventDefault();
        });

        // prevent ctrl+s (save as)
        document.addEventListener('keydown', event => {
            if (event.key === 's' && event.ctrlKey) {
                event.preventDefault();
            }
        });

        window.process = {
            ...window.process,
        };
    };

    render() {
        return (
            <div>
                <Router basename={process.env.PUBLIC_URL}>
                    <Navigation />
                    <div className="container body-content">
                        <Suspense fallback={
                            <div className="loading">
                                <ClipLoader
                                    color={"#823794"}
                                    size={100}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        }>
                            <Routes>
                                <Route exact path="/" element={<Home />}></Route>
                                <Route
                                    exact
                                    path="/gallery"
                                    element={<Gallery />}
                                ></Route>
                                <Route
                                    exact
                                    path="/about"
                                    element={<About />}
                                ></Route>
                                <Route path='*' element={<Navigate to='/' />} />
                            </Routes>
                        </Suspense>
                    </div>
                    <Footer />
                </Router >
            </div >
        );
    }
}

export default App;
