import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="divider thick"></div>
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src="/images/logo.png" alt="Water Colors by Peter" />
                    </div>
                    <div className="pages">
                        <Link to="/">Home</Link>
                        <Link to="/gallery">Gallery</Link>
                        <Link to="/about">About</Link>
                        <a className="donate" href="https://secure.pancan.org/site/Donation2?df_id=2681&mfc_pref=T&2681.donation=form1" target="_blank" rel="noreferrer">
                            Donate
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
